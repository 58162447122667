<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>

      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="中大型水库后扶相关规划编审情况统计"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <div class="view_table" v-if="false">
      <el-table v-loading="loading" ref="table" :data="listData" style="width: 100%;overflow-x: auto;" height="400px">
        <el-table-column fixed type="index" label="序号" width="60" align="center">
          <template slot-scope="scope">
            <span v-if="scope.$index != 0">{{ scope.$index }}</span>
          </template>
        </el-table-column>

        <el-table-column fixed align="center" prop="countyId" width="140" label="行政区域">
          <template slot-scope="scope">
            <div v-if="scope.$index == 0">{{ scope.row.county }}</div>
            <div v-else>
              <el-cascader  :append-to-body="false"  
                v-model="scope.row.countyId"
                size="mini"
                :disabled="type == 'view' || type == 'charts'"
                :options="levalRegion"
                :props="treeProps"
                @change="handleChange($event, scope.$index)"
                :show-all-levels="false"
                placeholder="请选择"
              >
              </el-cascader>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="规划总数（个）">
          <el-table-column width="120" align="center" prop="planningCompilation" label="规划审批">
            <template slot-scope="scope">
              <div v-if="scope.$index == 0">{{ calculateTheTotal('planningCompilation') }}</div>
              <el-input
                v-else
                size="mini"
                :disabled="type == 'view' || type == 'charts'"
                type="number"
                min="0"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="scope.row.planningCompilation"
                placeholder="请输入"
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column width="120" align="center" prop="planningApproval" label="编制规划">
            <template slot-scope="scope">
              <div v-if="scope.$index == 0">{{ calculateTheTotal('planningApproval') }}</div>
              <el-input
                v-else
                size="mini"
                :disabled="type == 'view' || type == 'charts'"
                type="number"
                min="0"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="scope.row.planningApproval"
                placeholder="请输入"
              >
              </el-input>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column align="center" label="移民后扶规划编制（个）">
          <el-table-column align="center" prop="planningCompilationSubtotal" label="小计">
            <template slot-scope="scope">
              <div v-if="scope.$index == 0">{{ calculateTheTotal('planningCompilationSubtotal') }}</div>
              <div v-else>
                {{ Number(scope.row.countyPlanningCompilation) + Number(scope.row.engineeringPlanningCompilation) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="220"
            prop="countyPlanningCompilation"
            label="以县为单位编制的规划（方案）"
          >
            <template slot-scope="scope">
              <div v-if="scope.$index == 0">{{ calculateTheTotal('countyPlanningCompilation') }}</div>
              <el-input
                v-else
                size="mini"
                :disabled="type == 'view' || type == 'charts'"
                type="number"
                min="0"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="scope.row.countyPlanningCompilation"
                placeholder="请输入"
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="170"
            prop="engineeringPlanningCompilation"
            label="以县为单位编制的规划"
          >
            <template slot-scope="scope">
              <div v-if="scope.$index == 0">{{ calculateTheTotal('engineeringPlanningCompilation') }}</div>
              <el-input
                v-else
                size="mini"
                :disabled="type == 'view' || type == 'charts'"
                type="number"
                min="0"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="scope.row.engineeringPlanningCompilation"
                placeholder="请输入"
              >
              </el-input>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column align="center" label="移民后扶规划审批（个）">
          <el-table-column align="center" prop="planningApprovalSubtotal" label="小计">
            <template slot-scope="scope">
              <div v-if="scope.$index == 0">{{ calculateTheTotal('planningApprovalSubtotal') }}</div>
              <div v-else>
                {{ Number(scope.row.countyPlanningApproval) + Number(scope.row.engineeringPlanningApproval) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="220"
            prop="countyPlanningApproval"
            label="以县为单位编制的规划（方案）"
          >
            <template slot-scope="scope">
              <div v-if="scope.$index == 0">{{ calculateTheTotal('countyPlanningApproval') }}</div>
              <el-input
                v-else
                size="mini"
                :disabled="type == 'view' || type == 'charts'"
                type="number"
                min="0"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="scope.row.countyPlanningApproval"
                placeholder="请输入"
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="240"
            prop="engineeringPlanningApproval"
            label="以工程为单位编制的规划（方案）"
          >
            <template slot-scope="scope">
              <div v-if="scope.$index == 0">{{ calculateTheTotal('engineeringPlanningApproval') }}</div>
              <el-input
                v-else
                size="mini"
                :disabled="type == 'view' || type == 'charts'"
                type="number"
                min="0"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="scope.row.engineeringPlanningApproval"
                placeholder="请输入"
              >
              </el-input>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column align="center" label='移民后扶"十四五"规划（个）'>
          <el-table-column align="center" width="110" prop="fourteenFivePlanningCompilation" label="编制（个）">
            <template slot-scope="scope">
              <div v-if="scope.$index == 0">{{ calculateTheTotal('fourteenFivePlanningCompilation') }}</div>
              <el-input
                v-else
                size="mini"
                :disabled="type == 'view' || type == 'charts'"
                type="number"
                min="0"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="scope.row.fourteenFivePlanningCompilation"
                placeholder="请输入"
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column align="center" width="110" prop="fourteenFivePlanningApproval" label="审批（个）">
            <template slot-scope="scope">
              <div v-if="scope.$index == 0">{{ calculateTheTotal('fourteenFivePlanningApproval') }}</div>
              <el-input
                v-else
                size="mini"
                :disabled="type == 'view' || type == 'charts'"
                type="number"
                min="0"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="scope.row.fourteenFivePlanningApproval"
                placeholder="请输入"
              >
              </el-input>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column align="center" prop="remarks" width="220" label="备注">
          <template slot-scope="scope">
            <div v-if="scope.$index == 0"></div>
            <el-input
              v-else
              size="mini"
              :disabled="type == 'view' || type == 'charts'"
              type="text"
              v-model="scope.row.remarks"
              placeholder="请输入"
            >
            </el-input>
          </template>
        </el-table-column>

        <el-table-column fixed="right" align="center" width="80" label="操作" v-if="type == 'add'">
          <template slot-scope="scope">
            <div v-if="scope.$index == 0"></div>
            <el-link v-else type="danger" @click="removeCol(scope.$index)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>

      <!-- <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination> -->
    </div>

    <el-row>
      <!-- 纵向表头设计 -->
      <el-col :span="5" style="background-color: #F9FAFE;">
        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTitletStyle">操作</div>
        </el-row>
        <el-row>
          <div class="leftTitletStyle">行政区域</div>
        </el-row>
        <el-row>
          <el-col :span="6" class="title_view3">
            <div class="firstTitletStyle">
              规划总数（个）
            </div>
          </el-col>
          <el-col :span="18">
            <el-row>
              <div class="leftTitletStyle">规划审批</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">编制规划</div>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="6" class="title_view3">
            <div class="firstTitletStyle">
              移民后扶规划编制（个）
            </div>
          </el-col>
          <el-col :span="18">
            <el-row>
              <div class="leftTitletStyle">小计</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">以县为单位编制的规划（方案）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">以县为单位编制的规划</div>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="6" class="title_view3">
            <div class="firstTitletStyle">
              移民后扶规划审批（个）
            </div>
          </el-col>
          <el-col :span="18">
            <el-row>
              <div class="leftTitletStyle">小计</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">以县为单位编制的规划（方案）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">以工程为单位编制的规划（方案）</div>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="6" class="title_view31">
            <div class="firstTitletStyle">
              移民后扶"十四五"规划（个）
            </div>
          </el-col>
          <el-col :span="18">
            <el-row>
              <div class="leftTitletStyle">编制（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">审批（个）</div>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <div class="leftTitletStyle1">备注</div>
        </el-row>
      </el-col>
      <el-col :span="2" style="background-color: #F9FAFE;">
        <el-row>
          <div class="leftTotalStyle">合计</div>
        </el-row>

        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTotalStyle"></div>
        </el-row>

        <!-- 项目基本情况 -->
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('planningCompilation') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('planningApproval') }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('planningCompilationSubtotal') }}</div>
          </div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('countyPlanningCompilation') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('engineeringPlanningCompilation') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- 前期工作 -->
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('planningApprovalSubtotal') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('countyPlanningApproval') }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('engineeringPlanningApproval') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('fourteenFivePlanningCompilation') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('fourteenFivePlanningApproval') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle1"></div>
          </el-col>
        </el-row>
      </el-col>

      <!-- 2.纵向数据展示 -->
      <el-col :span="17">
        <div class="main_box">
          <!-- -纵列数据遍历 -->
          <div :style="boxWidth" class="clearFloat">
            <div>
              <el-row class="flex">
                <el-row style="width: 150px;" v-for="(col, index) in listData" :key="col.id">
                  <el-col :span="24">
                    <el-row v-if="type == 'add'">
                      <div class="leftTitletStyle">
                        <el-link type="danger" @click="removeCol(index)">删除</el-link>
                      </div>
                    </el-row>

                    <el-row v-if="type == 'edit'">
                      <div class="leftTitletStyle"></div>
                    </el-row>

                    <el-col>
                      <template v-for="key1 in Object.keys(col)">
                        <el-row v-if="key1 == 'countyId'">
                          <div class="contentStyle">
                            <el-cascader  :append-to-body="false"  
                              v-if="type == 'add' || type == 'edit'"
                              v-model="col[key1]"
                              size="mini"
                              :options="levalRegion"
                              :props="treeProps"
                              @change="handleChange($event, index)"
                              :show-all-levels="false"
                              placeholder="请选择"
                            >
                            </el-cascader>
                            <div v-else>
                              {{ col['county'] }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'planningCompilation'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'planningApproval'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'planningCompilationSubtotal'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{
                               ( Number(col['countyPlanningCompilation']) * 1000 + Number(col['engineeringPlanningCompilation']) * 1000) / 1000
                              }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'countyPlanningCompilation'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'engineeringPlanningCompilation'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'planningApprovalSubtotal'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{ (Number(col['countyPlanningApproval'])  * 1000 + Number(col['engineeringPlanningApproval']) * 1000 )  / 1000 }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'countyPlanningApproval'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'engineeringPlanningApproval'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'fourteenFivePlanningCompilation'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'fourteenFivePlanningApproval'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'remarks'">
                          <div class="contentStyle1">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="textarea"
                              resize="none"
                              :rows="10"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == '' ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>
                      </template>
                    </el-col>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'
import { _getLevelRegion } from '@/api/keepHome'

import {
  _PlanningSituationAdd,
  _PlanningSituationInfo,
  _PlanningSituationEdit
} from '@/api/modular/postImmigrationService/policy/mediumSized.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      loading: false,
      options: [],
      treeProps: { label: 'name', value: 'id', children: 'childRegions', checkStrictly: true },
      levalRegion: []
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  computed: {
    //
    boxWidth() {
      let num = 150 * this.listData.length
      return `width:${num}px`
    }
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {},

  methods: {
    handleChange(value, index) {
      let obj = this.getPids(value)
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    calculateTheTotal(name) {
      let sum = 0
      if (name == 'planningCompilationSubtotal') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum +=
            (Number(ele['countyPlanningCompilation']) * 100 + Number(ele['engineeringPlanningCompilation']) * 100) / 100
        }
      } else if (name == 'planningApprovalSubtotal') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          // {{ Number(scope.row.countyPlanningCompilation) + Number(scope.row.engineeringPlanningCompilation) }}
          sum += (Number(ele['countyPlanningApproval']) * 100 + Number(ele['engineeringPlanningApproval']) * 100) / 100
        }
      } else {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += Number(ele[name])
        }
      }

      return sum
    },
    refreshYear(year) {
      this.searchObj.year = year
    },
    add(type, row) {
      this.type = type
      this.row = row
      // let obj = {
      //   year: this.searchObj.year, //年份
      //   countyId: null, //县（市区）
      //   county: '总计', //县（市区）
      //   planningCompilation: null, //规划编制 规划总数（个）
      //   planningApproval: null, //规划审批 规划总数（个）
      //   planningCompilationSubtotal: null, //小计 移民后扶规划编制（个）
      //   countyPlanningCompilation: null, //以县为单位编制的规划 移民后扶规划编制（个）
      //   engineeringPlanningCompilation: null, //以工程为单位编制的规划（方案） 移民后扶规划编制（个
      //   planningApprovalSubtotal: null, //小计 移民后扶规划审批（个）
      //   countyPlanningApproval: null, //以县为单位编制的规划 移民后扶规划审批（个）
      //   engineeringPlanningApproval: null, //以工程为单位编制的规划（方案） 移民后扶规划审批（个）
      //   fourteenFivePlanningCompilation: null, //编制（个） 移民后扶“十四五”规划
      //   fourteenFivePlanningApproval: null, //审批（个） 移民后扶“十四五”规划
      //   remarks: '', //备注
      //   countyPids: '' //pids
      // }
      // this.listData.unshift(obj)
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row

      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },
    editCols() {
      this.type = 'edit'
      this.title = '编辑'
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    btnClose() {
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            countyId: null, //县（市区）
            county: '', //县（市区）
            planningCompilation: null, //规划编制 规划总数（个）
            planningApproval: null, //规划审批 规划总数（个）
            planningCompilationSubtotal: null, //小计 移民后扶规划编制（个）
            countyPlanningCompilation: null, //以县为单位编制的规划 移民后扶规划编制（个）
            engineeringPlanningCompilation: null, //以工程为单位编制的规划（方案） 移民后扶规划编制（个
            planningApprovalSubtotal: null, //小计 移民后扶规划审批（个）
            countyPlanningApproval: null, //以县为单位编制的规划 移民后扶规划审批（个）
            engineeringPlanningApproval: null, //以工程为单位编制的规划（方案） 移民后扶规划审批（个）
            fourteenFivePlanningCompilation: null, //编制（个） 移民后扶“十四五”规划
            fourteenFivePlanningApproval: null, //审批（个） 移民后扶“十四五”规划
            remarks: '', //备注
            countyPids: '' //pids
          }

          // if (this.listData.length) {
          //   let flag = this.listData.every(ele => ele.countyId)
          //   if (flag) {
          //     this.listData.splice(this.listData.length, 0, obj)
          //   } else {
          //     this.$message.error('请选择县（市区）')
          //   }
          // } else {
          //   this.listData.splice(this.listData.length, 0, obj)
          // }
          this.listData.splice(this.listData.length, 0, obj)
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    refreshTable() {
      this.getBasicInfo()
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }
      _PlanningSituationInfo(params).then(res => {
        if (res.code == 200) {
          this.listData = [res.data]
          // let obj = {
          //   year: this.searchObj.year, //年份
          //   countyId: null, //县（市区）
          //   county: '总计', //县（市区）
          //   planningCompilation: null, //规划编制 规划总数（个）
          //   planningApproval: null, //规划审批 规划总数（个）
          //   planningCompilationSubtotal: null, //小计 移民后扶规划编制（个）
          //   countyPlanningCompilation: null, //以县为单位编制的规划 移民后扶规划编制（个）
          //   engineeringPlanningCompilation: null, //以工程为单位编制的规划（方案） 移民后扶规划编制（个
          //   planningApprovalSubtotal: null, //小计 移民后扶规划审批（个）
          //   countyPlanningApproval: null, //以县为单位编制的规划 移民后扶规划审批（个）
          //   engineeringPlanningApproval: null, //以工程为单位编制的规划（方案） 移民后扶规划审批（个）
          //   fourteenFivePlanningCompilation: null, //编制（个） 移民后扶“十四五”规划
          //   fourteenFivePlanningApproval: null, //审批（个） 移民后扶“十四五”规划
          //   remarks: '', //备注
          //   countyPids: '' //pids
          // }
          // this.listData.unshift(obj)
          console.log(this.listData, '========获取详情==========')
        }
      })
    },
    submitForm() {
      console.log(this.listData, '===submitForm===')
      let flag = this.listData.every(ele => ele.countyId)

      switch (this.type) {
        case 'add':
          if (flag) {
            _PlanningSituationAdd(this.listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }
          break
        case 'edit':
          if (flag) {
            _PlanningSituationEdit(this.listData[0]).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/formview.scss';
</style>
